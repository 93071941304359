import { Button, Card, Grid, InputLabel, Checkbox, FormControlLabel } from '@mui/material';
import DashboardNavbar from 'components/DashboardNavbar';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { useEffect, useState } from 'react';
import { getBlacklistedCountries } from 'services/blacklist/get-blacklisted-countries';
import { getCountries } from 'services/blacklist/get-countries';
import { removeBlacklistedCountries } from 'services/blacklist/removeBlacklistedCountries';
import { sendBlacklistedCountries } from 'services/blacklist/sendBlacklistedCountries';

export default function Blacklist() {
  const [data, setData] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [visibleContinents, setVisibleContinents] = useState({}); // Track visibility for each continent

  useEffect(() => {
    // set initial blacklisted countries
    async function getData() {
      const res = await getBlacklistedCountries();
      const data = res.data;
      const initialValues = {};
      data.map((obj) => {
        // console.log(obj);

        initialValues[obj.continent] = obj.countries;
      });
      setSelectedValues(initialValues);
    }
    getData();
  }, []);

  const handleChange = (continent, code) => {
    setSelectedValues((prev) => ({
      ...prev,
      [continent]: prev[continent]?.includes(code)
        ? prev[continent].filter((c) => c !== code) // Remove if already selected
        : [...(prev[continent] || []), code] // Add if not selected
    }));
  };

  const toggleVisibility = (continent) => {
    setVisibleContinents((prev) => ({
      ...prev,
      [continent]: !prev[continent]
    }));
  };

  const selectAll = (continent, countries) => {
    setSelectedValues((prev) => ({
      ...prev,
      [continent]: countries.map((c) => c.code) // Select all country codes for the continent
    }));
  };

  const deselectAll = (continent) => {
    setSelectedValues((prev) => ({
      ...prev,
      [continent]: [] // Deselect all for the continent
    }));
  };

  useEffect(() => {
    async function getData() {
      const res = await getCountries();
      setData(res.data);
    }
    getData();
  }, []);

  async function sendData() {
    const allCodes = [];
    const selectedArray = Object.values(selectedValues).flat();

    // Get all countries' codes
    data.forEach((obj) => {
      obj.countries.forEach((obj2) => {
        allCodes.push(obj2.code);
      });
    });

    // Get unselected countries
    const unSelectedCountries = allCodes.filter((e) => !selectedArray.includes(e) && e);

    // logs
    // console.log('All Codes:', allCodes);
    // console.log('Selected:', selectedArray);
    // console.log('Unselected:', unSelectedCountries);

    const res = await sendBlacklistedCountries(selectedArray);
    const res2 = await removeBlacklistedCountries(unSelectedCountries);
    if ((res.status === 200 || res.status === 201) && (res2.status === 200 || res2.status === 201)) {
      alert('Changes saved!');
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={5}>
          <Grid container spacing={3} direction='row' justifyContent='center' alignItems='stretch'>
            <Grid item xs={6}>
              <Card position='relative' borderRadius='lg'>
                <MDBox p={3}>
                  <MDTypography variant='h5' fontWeight='bold' textTransform='capitalize' fontSize='1.2rem' mb={2}>
                    Blacklisting countries
                  </MDTypography>
                  {data.map(({ continent, countries }) => (
                    <div key={continent} style={{ marginBottom: '20px' }}>
                      <InputLabel
                        id={`${continent}-label`}
                        onClick={() => toggleVisibility(continent)}
                        style={{ cursor: 'pointer', fontWeight: 'bold' }}
                      >
                        {continent} ({selectedValues[continent]?.length || 0} / {countries.length})
                      </InputLabel>

                      {/* Buttons for Select All and Deselect All */}
                      {visibleContinents[continent] && (
                        <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                          <Button
                            variant='contained'
                            size='small'
                            onClick={() => selectAll(continent, countries)}
                            style={{ marginRight: '5px', color: 'white' }}
                          >
                            Select All
                          </Button>
                          <Button
                            variant='contained'
                            size='small'
                            style={{ color: 'white' }}
                            onClick={() => deselectAll(continent)}
                          >
                            Deselect All
                          </Button>
                        </div>
                      )}

                      {visibleContinents[continent] &&
                        countries.map(({ code, name }) => (
                          <FormControlLabel
                            key={code}
                            control={
                              <Checkbox
                                checked={selectedValues[continent]?.includes(code) || false}
                                onChange={() => handleChange(continent, code)}
                              />
                            }
                            label={name}
                          />
                        ))}
                    </div>
                  ))}
                  <Button variant='contained' style={{ color: '#ffffff', marginTop: '5px' }} onClick={() => sendData()}>
                    Apply
                  </Button>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
